<template>
  <b-card no-body>
    <div class="p-2">
      <b-card-title>{{ $t("ForwardRules.Title") }}</b-card-title>
    </div>
    <page-loader :loading="isLoading">
      <b-table
        :fields="tableFields"
        :items="rules"
        show-empty
        :empty-text="$t('Generic.InvoiceListEmpty')"
      >
        <template #cell(actions)="data">
          <b-button size="sm" class="float-right" variant="danger" @click="removeRule({ resourceId: data.item.id });">{{$t('Generic.Delete')}}</b-button>
        </template>
      </b-table>
    </page-loader>
  </b-card>
</template>

<script>
import PageLoader from "@/app/components/layout/PageLoader.vue";
import { BCard, BTable, BCardTitle, BButton } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { PageLoader, BCard, BTable, BCardTitle, BButton },
  async created() {
    this.isLoading = true;
    this.fetchRules();
    this.isLoading = false;
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({ rules: "orderForwardRules/list" }),
    tableFields() {
      return [
        {
          key: "owningConcernName",
          label: this.$t('ForwardRules.Concern'),
          sortable: true,
        },
        {
          key: "locationName",
          label: this.$t('ForwardRules.Location'),
          sortable: true,
        },
        {
          key: "infocardName",
          label: this.$t('ForwardRules.Infocard'),
          sortable: true,
        },
        {
          key: "userName",
          label: this.$t('ForwardRules.ForwardedUser'),
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    ...mapActions({ fetchRules: "orderForwardRules/fetchList", removeRule: "orderForwardRules/delete" }),
  },
};
</script>